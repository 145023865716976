import axios from 'axios';

new Vue({
  el: '#mostviewed',
  data: function () {
    return {
      post: false,
      error: false,
    }
  },
  computed: {
    excerpt() {
      if (Object.hasOwnProperty.call(this.post, 'excerpt') && Object.hasOwnProperty.call(this.post.excerpt, 'rendered')) {
        const stripped = this.post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "");
        return stripped.substring(0, 49) + '...';
      }
      return '';
    },
  },
  methods: {
    async getOEmbed() {
      try {
        const response = await axios.get(`https://youtube.com/oembed?url=${this.post.acf.video}&format=json`);
        return response.data;
      } catch (e) {
        this.error = e;
      }
    },
    async getPost() {
      try {
        const response = await axios.get('/wp-json/wordpress-popular-posts/v1/popular-posts?_embed&limit=1&range=last30days&taxonomy=category&term_id=45');
        if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
          this.post = response.data[0];
          const oembed = await this.getOEmbed();
          Vue.set(this.post, 'oembed', oembed ? oembed : false);
          sessionStorage.setItem('mostviewed', JSON.stringify(this.post));
        }
      } catch (e) {
        this.error = e;
      }
    }
  },
  async created() {
    const post = sessionStorage.getItem('mostviewed');
    if (post) {
      this.post = JSON.parse(post);
    } else {
      this.getPost();;
    }
  }
});